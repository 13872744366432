<template>
    <div class="row">
        <div class="col-12">
            <b-card :header="$route.params.domain">
                <div class="p-3 pl-3 pb-3 pt-0">
                    <b-row class="mb-3">
                        <b-col>
                            <b-button variant="primary" class="mr-3" v-b-modal.modal-create> New Record </b-button>

                            <VDropdown>
                                <b-button variant="secondary" class="mr-3 ml-2">
                                    <feather-icon icon="SearchIcon" class="mr-2" size="15" />
                                    <span class="align-middle">Search-items</span>
                                </b-button>
                                <!-- This will be the content of the popover -->
                                <template #popper>
                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="name pattern" label-for="namePattern">
                                                <b-form-input placeholder="name pattern" v-model="queryNamePattern" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="record id" label-for="recordId">
                                                <b-form-input placeholder="record id" v-model="queryRecordId" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="queryType" label-for="queryType">
                                                <v-select id="queryType" multiple v-model="queryRecordType" :options="['All', ...option]" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1 mt-3">
                                        <b-col>
                                            <b-button variant="secondary" v-close-popper="true" @click="search"> Search</b-button>
                                            <b-button class="ml-2" variant="secondary" @click="clearQueryCondition"> Clear</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </VDropdown>
                        </b-col>
                    </b-row>

                    <b-overlay :show="register_show_overlay" rounded="sm">
                        <b-modal
                            id="modal-create"
                            cancel-variant="secondary"
                            ok-title="Add"
                            cancel-title="Cancel"
                            centered
                            title="new record"
                            @ok="addRecord"
                        >
                            <b-form>
                                <b-form-group label="record name" label-for="recordName">
                                    <b-form-input id="recordName" placeholder="record name ex. www" v-model="recordName" />
                                </b-form-group>
                                <b-form-group label="type" label-for="type">
                                    <v-select id="type" v-model="selectedType" :options="option" />
                                </b-form-group>

                                <b-form-group label="ttl" label-for="ttl">
                                    <b-form-input id="ttl" type="number" placeholder="minimum 300 second" v-model="ttl" />
                                </b-form-group>
                            </b-form>
                        </b-modal>
                    </b-overlay>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="remote_q_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="pagination"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->

                                <span v-if="props.column.field === 'forbidden'">
                                    <b-badge v-if="!props.row['forbidden']" pill variant="success" class="mr-3">active</b-badge>
                                    <b-badge v-else pill class="mr-3">forbidden</b-badge>
                                </span>

                                <span v-else-if="props.column.field === 'action1'">
                                    <b-button variant="primary" @click="openManagerRuleTable(props.row)">Manager Rules</b-button>
                                    <b-button class="ml-3" variant="primary" @click="open_row_view_edit(props.row.originalIndex)"
                                        >Edit/Delete</b-button
                                    >
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>

                    <!-- modal_row_view_update-->
                    <b-modal
                        id="modal_row_view_update"
                        size="lg"
                        @hide="handleHide"
                        title="view/edit"
                        cancel-variant="secondary"
                        cancel-title="Close"
                        centered
                    >
                        <b-overlay :show="modal_row_view_overlay" rounded="sm">
                            <b-form>
                                <b-form-group label="record name" label-for="recordName">
                                    <b-form-input id="recordName" placeholder="record name ex. www" v-model="recordName" disabled />
                                </b-form-group>
                                <b-form-group label="type" label-for="type">
                                    <v-select id="type" v-model="selectedType" :options="option" disabled />
                                </b-form-group>

                                <b-form-group label="ttl" label-for="ttl">
                                    <b-form-input id="ttl" type="number" placeholder="minimum 300 second" v-model="ttl" />
                                </b-form-group>

                                <b-form-group>
                                    <label>Active:</label>
                                    <b-form-checkbox v-model="status" name="check-button" class="custom-control-success" switch> </b-form-checkbox>
                                </b-form-group>
                            </b-form>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left p-0">
                                    <b-button variant="danger" @click="deleteRecord">Delete</b-button>
                                </b-col>
                                <b-col cols="8" class="text-right p-0">
                                    <b-button variant="primary" class="mr-3" @click="updateRecord">Update</b-button>
                                    <b-button variant="secondary" @click="$bvModal.hide('modal_row_view_update')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal>

                    <!-- modal_row_view_rules-->
                    <b-modal
                        id="modal_row_view_rules"
                        size="xl"
                        @hide="handleHide"
                        cancel-variant="secondary"
                        cancel-title="Close"
                        hide-footer
                        scrollable
                        centered
                    >
                        <b-overlay :show="modal_row_view_overlay" rounded="sm">
                            <rule-mgr
                                :record-id="rule_RecordId"
                                :record-type="rule_RecordType"
                                :record-name="rule_RecordName"
                                :domain="rule_Domain"
                            />
                        </b-overlay>
                    </b-modal>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";

import RuleMgr from "./ruleMgr.vue";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormCheckbox,
        VueGoodTable,
        vSelect,
        RuleMgr,
    },

    methods: {
        open_row_view_edit(rowid) {
            this.focus = this.row_data[rowid];
            this.recordName = this.focus.name;
            this.selectedType = this.focus.type;
            this.ttl = this.focus.ttl;
            this.status = !this.focus.forbidden;
            this.$bvModal.show("modal_row_view_update");
        },

        openManagerRuleTable(row) {
            this.rule_RecordId = row.id;
            this.rule_RecordType = row.type;
            this.rule_RecordName = row.name;
            this.rule_Domain = this.$route.params.domain;
            this.$bvModal.show("modal_row_view_rules");
        },
        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },
        clearQueryCondition() {
            this.queryNamePattern = "";
        },

        async search() {
            this.$refs["remote_q_table"].reset();
            this.offset = 0;
            await this.refreshTable();
        },

        async refreshTable() {
            this.table_show_overlay = true;

            let queryRecordType = [];
            if (this.queryRecordType === null || this.queryRecordType.length === 0 || this.queryRecordType.indexOf("All") != -1) {
                queryRecordType = null;
            } else {
                for (let i = 0; i < this.queryRecordType.length; i++) {
                    queryRecordType.push(this.queryRecordType[i]);
                }
            }

            let resp = await this.$api.record.queryRecord(
                parseInt(this.$route.params.domainId),
                this.queryNamePattern,
                parseInt(this.queryRecordId),
                queryRecordType,
                this.limit,
                this.offset,
                this.$store.state.auth.my_web_token
            );
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.records;
            this.totalRows = resp.result.count;
        },

        clearQueryCondition() {
            this.queryNamePattern = "";
            this.queryRecordId = "";
            this.queryRecordType = ["All"];
        },

        async updateRecord(bvModalEvt) {
            //validate input
            let validateResult = this.validateInput();
            if (validateResult !== null) {
                bvModalEvt.preventDefault();
                this.$bvToast.toast(validateResult, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let resp = await this.$api.record.updateRecord(this.focus.id, parseInt(this.ttl), !this.status, this.$store.state.auth.my_web_token);

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("expiration updated", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            this.refreshTable();
            this.$bvModal.hide("modal_row_view_update");
            (this.recordName = ""), (this.selectedType = "A"), (this.content = ""), (this.ttl = 300), (this.weight = 100);
        },

        async deleteRecord() {
            let value = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete record [${this.focus.type}-${this.focus.name}] ?`, {
                title: "Delete Record",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "No",
                cancelVariant: "secondary",
                hideHeaderClose: false,
                centered: true,
            });
            if (value) {
                let resp = await this.$api.record.deleteRecord(this.focus.id, this.$store.state.auth.my_web_token);
                if (resp.err !== null) {
                    this.$bvToast.toast(resp.err, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                if (resp.result.meta_status < 0) {
                    this.$bvToast.toast(resp.result.meta_message, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                this.$bvToast.toast("domain deleted", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                this.refreshTable();
                this.$bvModal.hide("modal_row_view_update");
            }
        },

        validateInput() {
            if (this.recordName == "") {
                return "record name error";
            }
            if (this.recordName.includes(" ")) {
                return "record name error";
            }
            if (parseInt(this.ttl) < 300) {
                return "ttl minimum is 300";
            }
            return null;
        },

        async addRecord(bvModalEvt) {
            //validate input
            let validateResult = this.validateInput();
            if (validateResult !== null) {
                bvModalEvt.preventDefault();
                this.$bvToast.toast(validateResult, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.register_show_overlay = true;
            let resp = await this.$api.record.addRecord(
                parseInt(this.$route.params.domainId),
                this.recordName,
                this.selectedType,
                parseInt(this.ttl),
                this.$store.state.auth.my_web_token
            );
            this.register_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("domain added", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            this.refreshTable();
            this.recordName = "";
            this.selectedType = "A";
            this.ttl = 300;
        },
    },
    mounted() {
        this.refreshTable();
    },
    data() {
        return {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Name",
                    field: "name",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Type",
                    field: "type",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "TTL",
                    field: "ttl",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Active",
                    field: "forbidden",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Action",
                    field: "action1",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            pagination: {
                enabled: true,
                mode: "pages",
                perPage: 10,
                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                setCurrentPage: 1,
            },
            row_data: [],
            queryNamePattern: "",
            queryRecordId: "",
            queryRecordType: ["All"],
            limit: 10,
            offset: 0,
            totalRows: 0,

            table_show_overlay: true,
            modal_row_view_overlay: false,
            register_show_overlay: false,

            option: ["A", "NS", "MX", "TXT", "CNAME", "CAA", "SOA"],
            recordName: "",
            selectedType: "A",
            ttl: 300,
            status: false,

            focus: {},
            rule_RecordId: 0,
            rule_RecordType: "",
            rule_RecordName: "",
            rule_Domain: "",
        };
    },
};
</script>
