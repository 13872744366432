<template>
    <div class="row">
        <div class="col-12">
            <b-card :header="recordName + '.' + domain">
                <div class="p-3">
                    <b-row class="mb-3 mt-3">
                        <b-col>
                            <b-button v-b-modal.modal-create-rule @click="resetFormInput"> Add Rule</b-button>
                        </b-col>
                        <b-overlay :show="register_show_overlay" rounded="sm">
                            <b-modal
                                id="modal-create-rule"
                                cancel-variant="secondary"
                                ok-title="Add"
                                cancel-title="Cancel"
                                centered
                                title="new rule"
                                @ok="addRule"
                            >
                                <b-form>
                                    <b-form-group label="Continent" label-for="continentCode">
                                        <v-select id="continentCode" v-model="continentCode" :options="continentOption" @input="continentInput" />
                                    </b-form-group>
                                    <b-form-group label="Country" label-for="countryCode">
                                        <v-select id="countryCode" v-model="countryCode" :options="countryOption" @input="countryInput" />
                                    </b-form-group>

                                    <b-form-group label="Type" label-for="type">
                                        <b-form-input id="type" placeholder="" :value="recordType" disabled />
                                    </b-form-group>

                                    <b-form-group label="Destination" label-for="destination">
                                        <b-form-input id="destination" :placeholder="contentExample" v-model="destination" />
                                    </b-form-group>

                                    <b-form-group label="weight" label-for="weight">
                                        <b-form-input id="weight" type="number" placeholder="weight 1-100" v-model="weight" />
                                    </b-form-group>
                                </b-form>
                            </b-modal>
                        </b-overlay>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="remote_q_table"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: 50,
                                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                                setCurrentPage: 1,
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->

                                <span v-if="props.column.field === 'type'">
                                    {{ recordType }}
                                </span>

                                <span v-else-if="props.column.field === 'continent_code'">
                                    {{ props.row["continent_code"] === ALL_Continent ? ALL_Continent : getFullContinent(props.row["continent_code"]) }}
                                </span>

                                <span v-else-if="props.column.field === 'country_code'">
                                    {{ props.row["country_code"] === ALL_Country ? ALL_Country : getFullCountry(props.row["country_code"]) }}
                                </span>

                                <span v-else-if="props.column.field === 'action1'">
                                    <b-button class="ml-3" variant="primary" @click="open_row_view_edit(props.row.originalIndex)"
                                        >Edit/Delete</b-button
                                    >
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>

                    <!-- modal_row_view_update-->
                    <b-modal
                        id="modal_row_view_update_rule"
                        size="lg"
                        @hide="handleHide"
                        title="view/edit"
                        cancel-variant="secondary"
                        cancel-title="Close"
                        centered
                    >
                        <b-overlay :show="modal_row_view_overlay" rounded="sm">
                            <b-form>
                                <b-form-group label="Continent" label-for="continentCode">
                                    <v-select id="continentCode" v-model="continentCode" :options="continentOption" @input="continentInput" />
                                </b-form-group>
                                <b-form-group label="Country" label-for="countryCode">
                                    <v-select id="countryCode" v-model="countryCode" :options="countryOption" @input="countryInput" />
                                </b-form-group>

                                <b-form-group label="Type" label-for="type">
                                    <b-form-input id="type" placeholder="" :value="recordType" disabled />
                                </b-form-group>

                                <b-form-group label="Destination" label-for="destination">
                                    <b-form-input id="destination" placeholder="rue content" v-model="destination" />
                                </b-form-group>

                                <b-form-group label="weight" label-for="weight">
                                    <b-form-input id="weight" type="number" placeholder="weight 1-100" v-model="weight" />
                                </b-form-group>
                            </b-form>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left p-0">
                                    <b-button variant="danger" @click="deleteRule">Delete</b-button>
                                </b-col>
                                <b-col cols="8" class="text-right p-0">
                                    <b-button variant="primary" class="mr-3" @click="updateRule">Update</b-button>
                                    <b-button variant="secondary" @click="$bvModal.hide('modal_row_view_update_rule')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { countryMap, continentMap } from "./countryMap";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormCheckbox,
        VueGoodTable,
        vSelect,
    },
    
    methods: {
        open_row_view_edit(rowid) {
            this.focus = this.row_data[rowid];
            this.continentCode =
                this.focus.continent_code === this.ALL_Continent ? this.ALL_Continent : this.getFullContinent(this.focus.continent_code);
            this.countryCode = this.focus.country_code === this.ALL_Country ? this.ALL_Country : this.getFullCountry(this.focus.country_code);
            let content = this.focus.destination;
            let str = content.substring(content.length - 1) == "." ? content.substring(0, content.length - 1) : content;
            this.destination = str;
            this.weight = this.focus.weight;
            this.$bvModal.show("modal_row_view_update_rule");
        },

        resetFormInput() {
            this.continentCode = this.ALL_Continent;
            this.countryCode = this.ALL_Country;
            this.destination = "";
            this.weight = "100";
        },

        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },

        getFullCountry(countryCode) {
            return countryCode + " | " + this.countryMap[countryCode].CountryName;
        },
        getFullContinent(continentCode) {
            return continentCode + " | " + this.continentMap[continentCode];
        },

        continentInput(code) {
            if (code===null||code === "") {
                this.continentCode = this.ALL_Continent;
                this.countryCode = this.ALL_Country;
                return;
            }
            if (code === this.ALL_Continent) {
                this.countryCode = this.ALL_Country;
                return;
            }
            if (this.countryCode === this.ALL_Country) {
                return;
            }
            let currentCountryCode = this.countryCode.split(" | ")[0];
            let countryInfo = this.countryMap[currentCountryCode];
            let currentContinentCode = code.split(" | ")[0];
            if (countryInfo.ContinentCode !== currentContinentCode) {
                this.countryCode = this.ALL_Country;
            }
        },
        countryInput(code) {
            if (code===null||code === "") {
                this.countryCode = this.ALL_Country;
                return;
            }
            if (code === this.ALL_Country) {
                return;
            }
            let countryCodeInput = code.split(" | ")[0];
            let countryInfo = this.countryMap[countryCodeInput];
            let continentCode = countryInfo.ContinentCode;
            this.continentCode = this.getFullContinent(continentCode);
        },

        async refreshTable() {
            this.table_show_overlay = true;
            let resp = await this.$api.rule.queryRules(this.recordId, this.$store.state.auth.my_web_token);
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.rules;
        },

        async updateRule(bvModalEvt) {
            //validate input
            let validateResult = this.validateInput();
            if (validateResult !== null) {
                bvModalEvt.preventDefault();
                this.$bvToast.toast(validateResult, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let content = this.destination;
            let dest = content.substring(content.length - 1) == "." ? content.substring(0, content.length - 1) : content;
            validateResult = this.validatorRuleContent(this.recordType, dest);
            if (validateResult !== null) {
                bvModalEvt.preventDefault();
                this.$bvToast.toast(validateResult, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let continentCode = this.continentCode.split(" | ")[0];
            let countryCode = this.countryCode.split(" | ")[0];

            let resp = await this.$api.rule.updateRule(
                parseInt(this.focus.id),
                continentCode,
                countryCode,
                dest,
                parseInt(this.weight),
                this.$store.state.auth.my_web_token
            );

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("rule updated", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            this.refreshTable();
            this.$bvModal.hide("modal_row_view_update_rule");

            this.continentCode = this.ALL_Continent;
            this.countryCode = this.ALL_Country;
            this.destination = "";
            this.weight = "100";
        },

        async deleteRule() {
            let value = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete rule ?`, {
                title: "Delete Record",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "No",
                cancelVariant: "secondary",
                hideHeaderClose: false,
                centered: true,
            });
            if (value) {
                let resp = await this.$api.rule.deleteRule(parseInt(this.focus.id), this.$store.state.auth.my_web_token);

                if (resp.err !== null) {
                    this.$bvToast.toast(resp.err, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                if (resp.result.meta_status < 0) {
                    this.$bvToast.toast(resp.result.meta_message, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                this.$bvToast.toast("rule deleted", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                this.refreshTable();
                this.$bvModal.hide("modal_row_view_update_rule");
            }
        },

        validateInput() {
            if (this.continent == "") {
                return "no continent";
            }
            if (this.country == "") {
                return "no country";
            }

            if (this.destination === "") {
                return "no content";
            }

            if (parseInt(this.weight) < 1 || parseInt(this.weight) > 100) {
                return "weight should be 1-100";
            }
            return null;
        },

        validatorRuleContent(recordType, content) {
            if (content === "") {
                return "dest error";
            }
            switch (recordType) {
                case "A": {
                    let ips = content.split(".");
                    if (ips.length != 4) {
                        return "record A format error";
                    }
                    return null;
                }
                case "NS": {
                    return null;
                }
                case "MX": {
                    let infos = content.split(" ");
                    if (infos.length != 2) {
                        return "record MX format error";
                    }
                    let pre = parseInt(infos[0]);
                    if (pre === 0) {
                        return "record MX format error";
                    }
                    return null;
                }
                case "TXT": {
                    return null;
                }
                case "CNAME": {
                    return null;
                }
                case "CAA": {
                    let infos = content.split(" ");
                    if (infos.length != 3) {
                        return "record CAA format error";
                    }
                    return null;
                }
                case "SOA": {
                    let infos = content.split(" ");
                    if (infos.length != 7) {
                        return "record SOA format error";
                    }
                    return null;
                }
                default:
                    return "unsupported record";
            }
        },

        async addRule(bvModalEvt) {
            //validate input
            let validateResult = this.validateInput();
            if (validateResult !== null) {
                bvModalEvt.preventDefault();
                this.$bvToast.toast(validateResult, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let content = this.destination;
            let dest = content.substring(content.length - 1) == "." ? content.substring(0, content.length - 1) : content;
            validateResult = this.validatorRuleContent(this.recordType, dest);
            if (validateResult !== null) {
                bvModalEvt.preventDefault();
                this.$bvToast.toast(validateResult, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let continentCode = this.continentCode.split(" | ")[0];
            let countryCode = this.countryCode.split(" | ")[0];

            this.register_show_overlay = true;
            let resp = await this.$api.rule.addRule(
                parseInt(this.recordId),
                continentCode,
                countryCode,
                dest,
                parseInt(this.weight),
                this.$store.state.auth.my_web_token
            );
            this.register_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("rule added", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            this.refreshTable();
            this.continent = this.ALL_Continent;
            this.country = this.ALL_Country;
            this.destination = "";
            this.weight = "100";
        },
    },
    mounted() {
        switch (this.recordType) {
            case "A":
                this.contentExample="ip address"
                break;
            case "NS":
                this.contentExample="ex. ns1.domain.com"
                break;
            case "SOA":
                this.contentExample="SOA content"
                break;
            case "CAA":
                this.contentExample="CAA content"
                break;
            case "TXT":
                this.contentExample="TXT content"
                break;
            case "MX":
                this.contentExample="ex. 10 mx.google.com"
                break;
            case "CNAME":
                this.contentExample="ex. www.domain.com"
                break;
            default:
                break;
        }


        this.countryMap = countryMap;
        this.continentMap = {};
        this.continentOption = [this.ALL_Continent];
        this.countryOption = [this.ALL_Country];

        for (const key in this.countryMap) {
            this.countryOption.push(this.getFullCountry(key));
            this.continentMap[this.countryMap[key].ContinentCode] = this.countryMap[key].ContinentName;
        }

        for (const key in this.continentMap) {
            this.continentOption.push(this.getFullContinent(key));
        }

        this.refreshTable();
    },

    props: {
        recordId: Number,
        recordType: String,
        recordName: String,
        domain: String,
    },
    data() {
        return {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Type",
                    field: "type",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Continent",
                    field: "continent_code",
                    tdClass: "text-center",
                    thClass: "text-center",
                },

                {
                    label: "Country",
                    field: "country_code",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Destination",
                    field: "destination",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Weight",
                    field: "weight",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Action",
                    field: "action1",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            row_data: [],

            table_show_overlay: true,
            modal_row_view_overlay: false,
            register_show_overlay: false,

            countryMap: {},
            continentMap: {},
            continentOption: [],
            countryOption: [],
            focus: {},

            contentExample:"",

            ALL_Continent: "AllContinent",
            ALL_Country: "AllCountry",
            continentCode: this.ALL_Continent,
            countryCode: this.ALL_Country,
            destination: "",
            weight: "100",
        };
    },
};
</script>
